import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    requested: false,
    streamKey: false,
    user: {},
    integration: {},
    blocked: null,
    game: {},
    bets: [],
    messages: [],
    selectedChip: null,
    historyOpened: false,
    chatOpened: false,
    totalBets: 0,
    totalWinAmount: 0,
    videoResolution: "auto",
    activeResolution: "auto",
    resolutions: {
        'auto': 'auto',
        '240p': '426x240',
        '360p': '640x360',
        '480p': '720x480',
        '720p': '1280x720',
        '1080p': '1920x1080',
        '1440p': '2560x1440',
        '2160p': '3840x2160',
    },
    volume: {
        muted: false,
        value: 0
    },
    gameSound: {
        muted: false,
        value: 100
    },
    error: {
        status: false,
        message: ""
    }
};

export const getStreamURL = createAsyncThunk('front/getSTreamURL', async () => {
   
    const response = await axios.get(`https://getvideostream.smartbet.am/`);

    return response?.data;
});

const frontSlice = createSlice({
    name: "front",
    initialState,
    reducers: {
        setUser(state, action) {
            const data = action.payload;
            state.requested = true;
            state.user = data.user;
            state.integration = data.integration;
            state.blocked = data.blocked;
            if (data.totalBets) {
                state.totalBets = data.totalBets;
            }
            
            if (typeof data.integration.gameConfig === 'string') {
                state.integration.gameConfig = JSON.parse(data.integration.gameConfig);
            }
            if (data.integration.gameConfig.CURRENCY_BET_AMOUNTS && !state.selectedChip) {
                state.selectedChip = data.integration.gameConfig.CURRENCY_BET_AMOUNTS[0];
            }
        },
        initialIncreaseVideoQuality(state, action) {
            state.videoResolution = 'auto';
            state.activeResolution = '3840x2160';
        },
        changeVideoResolution(state, action) {
            state.videoResolution = action.payload;
            state.activeResolution = state.resolutions[action.payload] || "auto"
        },
        updateUser(state, action) {
            state.user = action.payload;
        },
        updateTotalBets(state, action) {
            state.totalBets = action.payload;
        },
        updateTotalWinAmount(state, action) {
            state.totalWinAmount = action.payload;
        },
        setGameData(state, action) {
            state.game = action.payload;
        },
        setBets(state, action) {
            state.bets = action.payload;
        },
        updateUserBlcoked(state, action) {
            state.blocked = action.payload;
        },
        unBlockPlayer(state, action) {
            state.blocked = null;
        },
        setSelectedChip(state, action) {
            state.selectedChip = parseInt(action.payload);
        },
        updateHistoryOpened(state, action) {
            state.historyOpened = action.payload;
        },
        toggleHistoryOpened(state, action) {
            state.historyOpened = !state.historyOpened;
        },
        updateChatOpened(state, action) {
            state.chatOpened = action.payload;
        },
        toggleChatOpened(state, action) {
            state.chatOpened = !state.chatOpened;
        },
        toggleSettingsOpened(state, action) {
            state.settingsOpened = !state.settingsOpened;
        },
        updateSettingsOpened(state, action) {
            state.settingsOpened = action.payload;
        },
        updateVolume(state, action) {
            state.volume.value = action.payload
        },
        updateMute(state, action) {
            state.volume.muted = action.payload
        },
        updateGameSound(state, action) {
            state.gameSound.value = action.payload;
        },
        updateMute(state, action) {
            state.gameSound.muted = action.payload;
        },
        setError(state, action) {
            state.error.status = true;
            state.error.message = action.payload;
        },
        clearError(state) {
            state.error.status = false;
            state.error.message = '';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getStreamURL.fulfilled, (state, action) => {
            state.streamKey = action.payload;
        })
    }
});

export const frontActions = frontSlice.actions;
export const frontReducer = frontSlice.reducer;