import { Row, Col } from "antd";
import BetsContainer from "../content/betsContainer/BetsContainer";
import "./GameStatusConatiner.scss";
import { t } from "i18next";

const GameStatusConatiner = ({frontStore, socket}) => {

    const game = frontStore?.game;
    let gameConfig = frontStore.integration.gameConfig;

    return (
        <Row className="game-status-container">
            <div className={`title ${(game.currentConf && !['Betting', 'Results'].includes(game.currentConf.stage)) ? 'show' : ''}`}>
                {(game.currentConf && !['Betting', 'Results'].includes(game.currentConf.stage))? t(game.currentConf.stage): ''}
            </div>
            <div className={`wrapper ${(game.currentConf && game.currentConf.stage === 'Betting') ? 'show' : ''}`}>
                <BetsContainer amounts={gameConfig.CURRENCY_BET_AMOUNTS} game={game} socket={socket} className={(game.currentConf && game.currentConf.stage === 'Betting') ? 'show' : ''} />
            </div>
            <div className={`win-wrapper ${(game.currentConf && game.currentConf.stage === 'Results') ? 'show' : ''}`}>
                {game.currentConf && game.currentConf.stage === 'Results' ? <Row>
                    <Col span={24}>
                    <span className="results">{t(game.currentConf.stage)}</span>
                    <p>{frontStore.totalWinAmount ? `You win ${frontStore.totalWinAmount}`: ''}</p>
                    </Col>
                </Row>: ''}
            </div>
        </Row>
    );
}

export default GameStatusConatiner;