import { useEffect, useContext, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../partial/Loader";
import FrontSocketContext from "../../store/FrontSocketContext";
import { frontActions, getStreamURL } from "../../store/slice/frontSlice";
import { Col, Row, message, Typography, Button } from "antd";
import "./FrontContent.scss"
import { chatActions } from "../../store/slice/chatSlice";
import TipBox from "./tip/TipBox";
import HistoryContent from "./history/History";
import ChatContent from "./chat/Chat";
import { betsHistoryActions } from "../../store/slice/betsHistory";
import { t } from "i18next";
import AmountContainer from "./footer/amountContainer/AmountContainer";
import Fullscreen from "./header/fullscreen/Foolscreen";
import History from "./header/history/History";
import Chat from "./header/chat/Chat";
import Sound from "./header/sound/Sound";
import Settings from "./header/settings/Settings";
import BetBox from "./content/betBox/BetBox";
import FlashphonerComponent from "./FlashphonerComponent/FlashphonerComponent";
import Mobile from "./mobile/Mobile";
import Maintanice from "./maintanice/Maintanice";
import GameStatusConatiner from "./gameStatusConatiner/GameStatusConatiner";
import Modal from "../partial/modal";
import SoundManager from "../../helpers/SoundManager";
import LanguageContext from "../../store/LanguageContext";
import { frontUserActions } from "../../store/slice/frontUserSlice";
import Limits from "./header/limits/Limits";
import { isMobileDevice } from "../../helpers/helpers";
import AdditionalBets from "./additionalBets/AdditionalBets";
import RenderCard from "../partial/RenderCard/RenderCard";
import TrumpCard from "../Games/Belote/TrumpCard";



const FrontContent = () => {
    const frontStore = useSelector((store) => store.front);
    const i18n = useContext(LanguageContext);
    // const [amount, setAmount] = useState('');
    const [openChat, setOpenChat] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [openError, setOpenError] = useState(frontStore.error.status);
    const [openTipSuccess, setOpenTipSuccess] = useState(false);
    const [hideCards, setHideCards] = useState("hide");
    const socket = useContext(FrontSocketContext);
    const dispatch = useDispatch();
    const {Title} = Typography;

    useEffect(() => {
        dispatch(getStreamURL());
      }, []);
    const toggleCards = () => {
        setHideCards(!hideCards)
    }



    // const changeAmount = useCallback((amountVal) => {
    //     setAmount(amountVal);
    // }, []);


    const bet = (betData) => {
        const amount = frontStore.selectedChip ?? gameConfig.CURRENCY_BET_AMOUNTS[0];
     
        if (amount) {
            betData.amount = amount;
            socket.emit("playerBet", betData)
        }
        else {
            alert("Please choose amount before Bet")
        }
    }
    
    useEffect(() => {
        if (frontStore?.user?.language) {
            i18n.changeLanguage(frontStore.user.language);
        }
    }, [frontStore?.user?.language]);
    
    useEffect(() => {
        if (socket) {
            socket.emit("playerInit");
            socket.on("playerInited", () => {
                socket.emit("join", "Player");
            })
            socket.on("playerChatMessages", (messages) => {
                dispatch(chatActions.updatePlayerMessages(messages))
            })
            socket.on("setUser", (user) => {
                i18n.changeLanguage(user.user.language);
                dispatch(frontUserActions.setUser(user));
                dispatch(frontActions.setUser(user));
            });
            socket.on("updateUser", (user) => {
                dispatch(frontUserActions.updateUser(user));
            });
            socket.on("updateTotalBets", (totalBets) => {
                dispatch(frontUserActions.updateTotalBets(totalBets));
            });
            socket.on("updateTotalWinAmount", (amount) => {
                dispatch(frontActions.updateTotalWinAmount(amount))
            })
            socket.on("playerUpdate", (data) => {
                dispatch(frontActions.setGameData(data))
            });
            socket.on("setBet", (bets) => {
                dispatch(frontActions.setBets(bets))
            })
            socket.on("sendChatMessage", (message) => {
                dispatch(chatActions.addPlayerMessage(message))
            });
            socket.on("setDeletedMessageId", (messageId) => {
                dispatch(chatActions.deletePlayerToDealerMessage(messageId))
            });
            socket.on("updateUserBlcoked", (data) => {
                dispatch(frontActions.updateUserBlcoked(data))
            });
            socket.on("unBlockPlayer", () => {
                dispatch(frontActions.unBlockPlayer());
            });
            socket.on("playerBetsHistory", (data) => {
                dispatch(betsHistoryActions.setHistory(data));
            });
            socket.on("setRoundBets", (data) => {
                dispatch(betsHistoryActions.setRoundBets(data));
            });
            socket.on("setTipSuccess", (e) => {
                setOpenTipSuccess(true)
                SoundManager.play('modal');
            })
            socket.on("gameError", (e) => {
                setOpenError(true);
                SoundManager.play('modal');
                dispatch(frontActions.setError(e.message));
            })
            socket.on("badMessage", () => {
                messageApi.open({
                    type: 'warning',
                    content: t('message_bad_wowrd'),
                });
            })
            socket.on("addLastRoundBetHistory", (data) => {
                dispatch(betsHistoryActions.addLastRoundBetHistory(data))
            });
        }
      }, [socket, dispatch, messageApi]);
      console.log("frontStore.streamKey ", frontStore.streamKey)
    if (!frontStore.requested || frontStore.streamKey == false) {
        return <Loader />
    }

    const game = frontStore?.game;
    let gameConfig = frontStore.integration.gameConfig;

    const coefficient = game?.resultInfo?.coefficient;
  
    if (game?.table?.isOnline === 0) {
        return <Maintanice />
    }
 
    return (!isMobileDevice() ? (
        <>
        <Modal
                visible={openError}
                size={"small"}
                background={"dark-background"}
                modalBodyClassName={"low-balance-body"}
                modalContentClassName="low-balance-modal"
            >
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                <Typography.Title level={4}>
                                    {t(frontStore.error.message)}
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Button onClick={() => {
                                    setOpenError(false);
                                    SoundManager.play('click');
                                }}>{t('Ok')}</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
        </Modal>
        <Modal
                visible={openTipSuccess}
                size={"small"}
                background={"dark-background"}
                modalBodyClassName={"low-balance-body"}
                modalContentClassName="low-balance-modal"
            >
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                <Typography.Title level={4}>
                                    {t('tank_you')}
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Button onClick={() => {
                                    setOpenTipSuccess(false);
                                    SoundManager.play('click');
                                }}>{t('Ok')}</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
        </Modal>
        <Row className="game-container game-front" id="game-container-1">
            
            <div className="game-wrapper">
                <FlashphonerComponent 
                    streamId={frontStore?.game?.table?.game?.streamId}
                    streamKey={frontStore?.streamKey}
                    activeResolution={frontStore.activeResolution}
                    resolutions={frontStore.resolutions}
                    volume={frontStore.volume}
                />
                <Row className="header">
                    <Col span={4}>
                        <Limits coefficient={coefficient}/>
                    </Col>
                    <Col span={20} className="right-buttons">
                        <Sound />
                        <Settings />
                        <Chat />
                        <History />
                        <Fullscreen />
                        
                    </Col>
                </Row>
                <Row className="game-comtainer">
                
                    <HistoryContent />
                    <ChatContent />
                    <GameStatusConatiner frontStore={frontStore} socket={socket}/>
                </Row>
                    {['Betting'].includes(game.currentConf?.stage) ? <AdditionalBets coefficient={coefficient} frontStore={frontStore} bet={bet}/>: ''}
                    {['Betting'].includes(game.currentConf?.stage) ? <div className={`bet-cards-wrapper ${!hideCards ? 'hide' : ''}`}>
                        <div className="score-wrapper">
                            <h3>Score {frontStore?.game?.resultInfo?.score?.player2 ?? 0 }</h3>
                            <span onClick={toggleCards}>{hideCards ? 'Show' : 'Hide'}</span>
                            <h3>Score {frontStore?.game?.resultInfo?.score?.player1 ?? 0}</h3>
                        </div>
                        <div className="content">
                            <Row className="cards-wrapper cards-wrapper-p2">
                                <Col span={24} className="cards-section">
                                    {
                                        [3,4,5,6,7,8].map((e, i) => {
                                            return (<RenderCard cardNumber={e} key={"p2"+e+i} i={i} player="p2" frontStore={frontStore} />)
                                        })
                                    }
                                </Col>
                                <Col span={24} className="cards-section">
                                    {
                                        [0,1,2].map((e, i) => {
                                            return (<RenderCard cardNumber={e} key={"p2"+e+i} i={i} player="p2" frontStore={frontStore} />)
                                        })
                                    }
                                </Col>
                            </Row>
                            <Row className="betting-trum-card-wrapper">
                                <TrumpCard game={game} />
                            </Row>
                            <Row className="cards-wrapper cards-wrapper-p1">
                                <Col span={24} className="cards-section">
                                    {
                                        [3,4,5,6,7,8].map((e, i) => {
                                            return (<RenderCard cardNumber={e} key={"p1"+e+i} i={i} player="p1" frontStore={frontStore}/>)
                                        })
                                    }
                                </Col>
                                <Col span={24} className="cards-section">
                                    {
                                        [0,1,2].map((e, i) => {
                                            return (<RenderCard cardNumber={e} key={"p1"+e+i}  i={i} player="p1" frontStore={frontStore} />)
                                        })
                                    }
                                </Col>
                            </Row>
                        </div>
                        <Row className="boxes-container">
                            <BetBox boxIndex={3} coefficient={coefficient?.player2?.win} frontStore={frontStore} bet={bet}/>
                            <BetBox boxIndex={2} coefficient={coefficient?.draw} frontStore={frontStore} bet={bet}/>
                            <BetBox boxIndex={1} coefficient={coefficient?.player1?.win} frontStore={frontStore} bet={bet}/>
                        </Row>
                    </div>: ''}
                
                <Row className="footer-conatiner">
                    <TipBox />
                    <AmountContainer label={t('balance')} value={"balance"} amount={parseFloat(frontStore?.user?.balance).toFixed(2) ?? "0.00"}/>
                    <AmountContainer label={t('total_bets')} value={"totalBets"} amount={frontStore.totalBets.toFixed(2) ? frontStore.totalBets : "0.00"}/>
                </Row>
            </div>
        </Row></>
    ): (<Mobile bet={bet} />)
    )
    // return (<div>
    //     {contextHolder}
    //   <div>Balance: {parseFloat(frontStore?.user?.balance).toFixed(2) ?? 0}</div>
    //   <Row><Col>User: {frontStore?.user?.username}</Col></Row>
    //   <TipBox />
    //   <History />

    //   <Row>
    //         <Col>
    //             <Button onClick={toggleLiveChat}>Chat</Button>
    //         </Col>
    //   </Row>
    //   <Chat openChat={openChat} toggleLiveChat={toggleLiveChat}/>
    //   <Row>
    //     <Col>
    //         {gameConfig.BET_AMOUNTS.map((curerentAmount) => {
    //             const addionalClass = curerentAmount === amount ? 'selected': '';
    //             return (<span key={`amount-${curerentAmount}`} className={`amounts ${addionalClass}`} onClick={() => changeAmount(curerentAmount)}>{curerentAmount}</span>)
    //         })}
    //     </Col>
    // </Row>
    // <Row>
    //     <Col span={10}>
    //         <span>P1</span>
    //         <ul>
    //         {
    //             coefficient?.player1 && Object.keys(coefficient?.player1).map((current) => {
                 
    //                 const isBated = bets.find((bet) => {
    //                     return (bet.player === 'player1' && bet.type === current);
    //                 });
    //                 const bettedClaassName = isBated ? 'betted': '';
    //                 return (<li 
    //                     key={`p1-${current}`} onClick={() => bet({
    //                     player: "player1",
    //                     type: current,
    //                     status: !!isBated
    //                 })}
    //                 className={`${bettedClaassName}`}
    //                 >{current} - {coefficient?.player1[current]}</li>)
    //             })
    //         }
    //         </ul>
    //     </Col>
    //     <Col span={2}>
    //         <span className={`${bettedDrawClaassName}`}>Draw</span>
    //         <span onClick={() => bet({
    //                     player: "",
    //                     type: "draw",
    //                     status: !!isDrawBated
    //                 })}
    //             className={`${bettedDrawClaassName}`}
    //         >{coefficient?.draw}</span>
    //     </Col>
    //     <Col span={10}>
    //         <span>P2</span>
    //         <ul>
    //         {
    //             coefficient?.player2 && Object.keys(coefficient?.player2).map((current) => {
    //                 const isBated = bets.find((bet) => {
    //                     return (bet.player === 'player2' && bet.type === current);
    //                 });
    //                 const bettedClaassName = isBated ? 'betted': '';
    //                 return (<li 
    //                     key={`p1-${current}`} 
    //                     onClick={() => bet({
    //                         player: "player2",
    //                         type: current,
    //                         status: !!isBated
    //                     })}
    //                     className={`${bettedClaassName}`}
    //             >{current} - {coefficient?.player2[current]}</li>)
    //             })
    //         }
    //         </ul>
    //     </Col>
    // </Row>
    //   <Row className="cards-wrapper">
    //     <Col span={20} className="cards-section">
    //         {
    //             [0,1,2].map((e, i) => {
    //                 return (<RenderCard cardNumber={e} key={"p1"+e+i}  i={i} player="p1" />)
    //             })
    //         }
    //     </Col>
    //     <Col span={20} className="cards-section">
    //         {
    //             [3,4,5,6,7,8].map((e, i) => {
    //                 return (<RenderCard cardNumber={e} key={"p1"+e+i} i={i} player="p1" />)
    //             })
    //         }
    //     </Col>
    //   </Row>
    //   <Row>
    //     <Col span={2}>
    //         <Title>Trump</Title>
    //         <TrumpCard game={game} />
    //     </Col>
    //   </Row>
    //   <Row className="cards-wrapper">
    //     <Col span={24} className="cards-section">
    //         {
    //             [0,1,2].map((e, i) => {
    //                 return (<RenderCard cardNumber={e} key={"p2"+e+i} i={i} player="p2" />)
    //             })
    //         }
    //     </Col>
    //     <Col span={24} className="cards-section">
    //         {
    //             [3,4,5,6,7,8].map((e, i) => {
    //                 return (<RenderCard cardNumber={e} key={"p2"+e+i} i={i} player="p2" />)
    //             })
    //         }
    //     </Col>
    // </Row>
    // </div>)
}

export default FrontContent;